import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ExitIntentAPI= createApi({
    reducerPath: 'ExitIntentAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
        tagTypes: ['ExitIntent'],
    }),
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        createQuery: builder.mutation({
            query(body) {
              return {
                url: '/enquiry/exit-intent',
                method: 'POST',
                body,
              }
            }

          })
    }),
})

export const { useCreateQueryMutation} = ExitIntentAPI;