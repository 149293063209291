import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [], // Array to store cart items
  },
  reducers: {
    addItemToCart: (state, action) => {
      state.items = action.payload; // Add item to cart
    },
    updateCartItem: (state, action) => {
      // const { id, quantity } = action.payload;
      // const itemIndex = state.items.findIndex(item => item.id === id);
      // if (itemIndex !== -1) {
      //   state.items[itemIndex].quantity = quantity; // Update item quantity
      // }
      state.items.push(action.payload);
    },
    removeItemFromCart: (state, action) => {
      const { _id } = action.payload;
      state.items = state.items.filter(item => item._id !== _id); // Remove item from cart
    },
  },
});

export const {
  addItemToCart,
  updateCartItem,
  removeItemFromCart,
} = cartSlice.actions;

export default cartSlice.reducer;