// components/CanonicalTag.js
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

const CanonicalTag = () => {
    const router = useRouter();
    let end_path=process.env.APP_URL+router.asPath;
    if((process.env.APP_URL+router.asPath).includes('?')){
         end_path=(process.env.APP_URL+router.asPath).split('?')[0]
    }
    const canonicalUrl = `${end_path}`; // Replace with your domain
    return (
        <Head>
            <link rel="canonical" href={canonicalUrl} />
        </Head>
    );
};

export default CanonicalTag;
