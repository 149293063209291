import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const SitemapAPI = createApi({
    reducerPath: 'SitemapAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
        tagTypes: ['SitemapAPI'],
    }),
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getCategoryList: builder.query({
            query: (path) => path,
            providesTags: ['SitemapAPI']
        }),
        getInfogaphicsList: builder.query({
            query: (path) => path,
            providesTags: ['SitemapAPI']
        }),
        getPagesList: builder.query({
            query: (path) => path,
            providesTags: ['SitemapAPI']
        }),
        getNewsList: builder.query({
            query: (path) => path,
            providesTags: ['SitemapAPI']
        }),
    }),
});

export const { useGetInfogaphicsListQuery , useGetNewsListQuery ,  useGetCategoryListQuery , useGetPagesListQuery} = SitemapAPI;