import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const CommonFormAPI = createApi({
    reducerPath: 'CommonFormAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
        tagTypes: ['CommonAPI'],
    }),
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        commonEnquiry: builder.mutation({
            query: ({url, data}) => ({
                url,
                method: 'POST',
                body : data,
            })
        })
    }),
});

export const { useCommonEnquiryMutation  } = CommonFormAPI;