import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome icons
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'; // Example of an arrow-up icon

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`${
        showButton ? 'block' : 'hidden'
      } fixed bottom-24 right-8 bg-[#1b5df3] z-50 text-white w-12 h-12 flex justify-center items-center rounded-full cursor-pointer shadow-md transition-opacity duration-300`}
      onClick={scrollToTop}
    >
      {/* You can use Font Awesome or other icons */}
      <FontAwesomeIcon icon={faArrowUp} />
    </div>
  );
};

export default ScrollToTopButton;
