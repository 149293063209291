import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const CommonAPI = createApi({
    reducerPath: 'CommonAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
        tagTypes: ['Common'],
    }),
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getItems: builder.query({
            query: (path) => path,
            providesTags: ['items']
        }),
        addItem: builder.mutation({
          query(endpoint,body) {
            return {
              url: endpoint,
              method: 'POST',
              body,
            }
          }
        }),
        createItem:  builder.mutation({
          query: (body) => ({
            url: '/enquiry/open-create',
            method: 'POST',
            body,
          })
        })
       
    }),
});

export const { useGetItemsQuery, useAddItemMutation, useCreateItemMutation } = CommonAPI;