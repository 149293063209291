import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const CartAPI = createApi({
    reducerPath: 'CartAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
        tagTypes: ['cartItems'],
    }),
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getCart: builder.mutation({
          query(body) {
            return {
              url: '/cart/list',
              method: 'POST',
              body,
            }
          },
          providesTags: ['cartItems']
        }),
        addCart: builder.mutation({
            query(body) {
              return {
                url: '/cart/add',
                method: 'POST',
                body,
              }
            }

          }),
          deleteCart:  builder.mutation({
            query: (body) => ({
              url: '/cart/delete',
              method: 'POST',
              body,
            })
          })
    }),
});

export const { useGetCartMutation, useAddCartMutation, useDeleteCartMutation } = CartAPI;