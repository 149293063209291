import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const DashboardAPI = createApi({
    reducerPath: 'DashboardAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
        tagTypes: ['DashboardAPI'],
    }),
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        ForgetPassword: builder.mutation({
            query: ({url , data}) => ({
                url,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['DashboardAPI'],
        }),
        ProfileUpdate:builder.mutation({
            query: ({url , data}) => ({
                url,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['DashboardAPI'],
        }),
    }),
});

export const { useForgetPasswordMutation , useProfileUpdateMutation} = DashboardAPI;