import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const SamplePageAPI = createApi({
    reducerPath: 'SamplePageAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
        tagTypes: ['SamplePage'],
    }),
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        samplepageEnquiry: builder.mutation({
            query: (body) => ({
                url: '/enquiry/sample-page-form',
                method: 'POST',
                body,
            })
        })
    }),
});

export const { useSamplepageEnquiryMutation } = SamplePageAPI;