import { configureStore } from '@reduxjs/toolkit';
import { NewsletterAPI } from "./Features/NewsletterAPI"
import { CommonAPI } from "./Features/CommonAPI"
import { PostApiRequest } from "./Features/PostApiRequest"

import { ReportAPI } from './Features/ReportAPI'
import { CaseStudiesAPI } from './Features/CaseStudiesAPI'
import { InfographicsAPI } from './Features/InfographicsAPI'
import { CartAPI } from './Features/CartAPI'
import authSlice from './reducers/authSlice';
import cartReducer from './reducers/cartSlice';
import { SamplePageAPI } from './Features/SamplePageAPI'
import {SitemapAPI} from './Features/SitemapAPI'
import {ExitIntentAPI} from './Features/ExitIntentAPI'
import { CommonFormAPI } from './Features/CommonFormAPI';
import { DashboardAPI } from './Features/DashboardAPI';

export const store = configureStore({
  reducer: {
    [NewsletterAPI.reducerPath]: NewsletterAPI.reducer,
    [CommonAPI.reducerPath]: CommonAPI.reducer,
    [PostApiRequest.reducerPath]: PostApiRequest.reducer,
    [ReportAPI.reducerPath]: ReportAPI.reducer,
    [CaseStudiesAPI.reducerPath]: CaseStudiesAPI.reducer,
    [InfographicsAPI.reducerPath]: InfographicsAPI.reducer,
    [CartAPI.reducerPath]: CartAPI.reducer,
    [SamplePageAPI.reducerPath]: SamplePageAPI.reducer,
    [ExitIntentAPI.reducerPath]:ExitIntentAPI.reducer,
    [CommonFormAPI.reducerPath]:CommonFormAPI.reducer,
    [DashboardAPI.reducerPath]:DashboardAPI.reducer,
    cart: cartReducer,
    [SitemapAPI.reducerPath] : SitemapAPI.reducer,
    auth: authSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat( DashboardAPI.middleware,CommonFormAPI.middleware,SitemapAPI.middleware ,SamplePageAPI.middleware,CartAPI.middleware,InfographicsAPI.middleware, NewsletterAPI.middleware, CaseStudiesAPI.middleware, ReportAPI.middleware, CommonAPI.middleware, ExitIntentAPI.middleware, PostApiRequest.middleware)

});

export default store;