
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
// import { cookies } from 'next/headers'

// const token=()=>{
//   if(cookies().has('access_token')){
//   }else{
//     let uuid=uuidv4();
//     cookies().set('access_token',uuid , { secure: true })
//   }
// }
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: uuidv4(), // Initialize the token as null
  },
  reducers: {
    // Modify the reducer to set the token
    setToken: (state, action) => {
        localStorage.setItem(action.payload);
      state.token = action.payload;
    },
  },
});

export const { setToken } = authSlice.actions;
export default authSlice.reducer;
