import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const InfographicsAPI = createApi({
    reducerPath: 'InfographicsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
        tagTypes: ['Infographics'],
    }),
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getInfographics: builder.query({
            query: (path) => path,
            providesTags: ['Infographics']
        }),
        getRandomInfo: builder.query({
            query: (path) => path,
            providesTags: ['Infographics']
        }),
        inforgraphicsEnquiry: builder.mutation({
            query: ({url, data}) => ({
                url,
                method: 'POST',
                body : data,
            })
        })
    }),
});

export const { useGetInfographicsQuery, useInforgraphicsEnquiryMutation , useGetRandomInfoQuery  } = InfographicsAPI;