import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const ReportAPI = createApi({
    reducerPath: 'ReportAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
        tagTypes: ['Reports'],
    }),
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getReports: builder.query({
            query: (path) => path,
            providesTags: ['Reports']
        }),
        getRandomReport: builder.query({
            query: (path) => path,
            providesTags: ['Reports']
        }),
    }),
});

export const { useGetReportsQuery , useGetRandomReportQuery} = ReportAPI;